import { createRef, memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { WEBSITE_TUNNEL_LABELS, WEBSITE_TUNNEL_TYPES } from "app/constants";
import { FormattedMessage } from "react-intl";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import "./WebsiteTunnelSwitcher.scss";
import {
	AsyncHotelOnlySearchFormContainer,
	AsyncSmartDPSearchFormContainer,
	AsyncStopoverSearchFormContainer,
} from "app/routes";
import classNames from "classnames";

const WebsiteTunnelSwitcher = ({ websiteTunnels = [] }) => {
	const hasMultipleTunnels = websiteTunnels?.length > 1;
	const [selectedIndex, setSelectedIndex] = useState(0);

	const switcherClass = classNames("website-tunnel-switcher", {
		"website-tunnel-switcher--multiple": hasMultipleTunnels,
	});

	const switcherRef = createRef(null);

	const handleFieldFocus = useCallback(() => {
		const fieldElement = switcherRef?.current;
		if (fieldElement) {
			const { top } = fieldElement.getBoundingClientRect();
			const currentScroll = window.scrollY;

			const offsetTop = currentScroll + top;

			window.scrollTo({
				top: offsetTop,
				behavior: "smooth",
			});
		}
	}, [switcherRef?.current]);

	return (
		<div className={switcherClass} ref={switcherRef}>
			<Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
				{hasMultipleTunnels && (
					<TabList>
						{websiteTunnels?.map((tunnel = {}, index) => {
							const websiteTunnelLabelId =
								WEBSITE_TUNNEL_LABELS[tunnel.websiteTunnelType];
							const isActive = index === selectedIndex;
							return (
								<Tab key={index}>
									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										isBold={isActive}
										variantMD={
											!isActive
												? TYPOGRAPHY_VARIANTS.REGULAR
												: TYPOGRAPHY_VARIANTS.LARGE
										}
									>
										<FormattedMessage id={websiteTunnelLabelId} />
									</Typography>
								</Tab>
							);
						})}
					</TabList>
				)}
				{websiteTunnels?.map((tunnel = {}, index) => (
					<TabPanel key={index}>
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.STOPOVER && (
							<AsyncStopoverSearchFormContainer
								disableFormPersistance
								onFieldFocus={handleFieldFocus}
							/>
						)}
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.HOLIDAYS && (
							<AsyncSmartDPSearchFormContainer
								disableFormPersistance
								onFieldFocus={handleFieldFocus}
							/>
						)}
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.HOTEL_ONLY && (
							<AsyncHotelOnlySearchFormContainer
								disableFormPersistance
								onFieldFocus={handleFieldFocus}
							/>
						)}
					</TabPanel>
				))}
			</Tabs>
		</div>
	);
};

WebsiteTunnelSwitcher.propTypes = {
	websiteTunnels: PropTypes.array,
};

export default memo(WebsiteTunnelSwitcher);
